export default {
    values: Object.freeze ({
        DRAFT: Symbol(1),
        PLANNED: Symbol(2),
        ORDERED: Symbol(3),
        PARTIALLY_RECEIVED: Symbol(8),
        RECEIVED: Symbol(4),
        PAID: Symbol(5),
        LITIGATION: Symbol(6),
        ERROR: Symbol(7),
    }),
    labels: {
        1: 'Brouillon',
        2: 'Planifiée',
        3: 'Commandée',
        8: 'Reçue partiellement',
        4: 'Reçue',
        5: 'Facturé',
        6: 'Réclamation',
        7: 'Erreur envoi api',
    },
    tags: {
        1: {severity: 'secondary', class: 'bg-surface-400'},
        2: {style: 'background-color: #A48DD3'},
        3: {severity: 'info'},
        8: {severity: 'success'},
        4: {severity: 'success'},
        5: {style: 'background-color: #256556'},
        6: {severity: 'danger'},
        7: {severity: 'danger'},
    },
    check(status: number, statuses: Array<Symbol>) {
        return statuses.map(_ => _.description).includes(String(status));
    }
}
