<template>
    <Breadcrumb
        :home="breadCrumbsFirstItem"
        :model="breadCrumbsItems"
        :pt="{
            root: {class: 'bg-primary-200 py-5 px-10'},
            list: {class: 'flex'}
        }"
    >
        <template #item="{ item, props }">
            <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a :href="href" v-bind="props.action" @click="navigate">
                    <span :class="[item.icon, 'text-color']" />
                    <span class="text-black font-bold">{{ item.label }}</span>
                </a>
            </router-link>
            <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                <span class="text-color">{{ item.label }}</span>
            </a>
        </template>
    </Breadcrumb>

    <div v-if="isPending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10 py-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    {{ product?.id ? 'Modifier un produit' : 'Ajouter un produit' }}
                </h1>
            </div>
        </div>

        <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
                <label for="name" class="text-dark-slate-600 uppercase tracking-widest">Nom</label>
                <InputText
                    id="name"
                    name="name"
                    v-model="form.name[locale]"
                    :invalid="form.invalid('name')"
                    @change="form.validate('name')"
                />
                <small v-if="form.invalid('name')" class="text-red-500"> {{ form.errors.name }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="supplier_reference" class="text-dark-slate-600 uppercase tracking-widest">Référence fournisseur</label>
                <InputText
                    id="supplier_reference"
                    type="text"
                    ref="supplier_reference"
                    v-model="form.supplier_reference"
                    :invalid="form.invalid('supplier_reference')"
                    @change="form.validate('supplier_reference')"
                />
                <small v-if="form.invalid('supplier_reference')" class="text-red-500"> {{ form.errors.supplier_reference }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="supplier_id" class="text-dark-slate-600 uppercase tracking-widest">Fournisseur</label>
                <Select
                    v-model="form.supplier_id"
                    :options="suppliersOptions"
                    optionLabel="name"
                    placeholder="Choisissez un fournisseur"
                    :invalid="form.invalid('supplier_id')"
                />
                <small v-if="form.invalid('supplier_id')" class="text-red-500"> {{ form.errors.supplier_id }} </small>
            </div>

            <div class="flex gap-2 flex-col">
                <label for="conditioning_value" class="text-dark-slate-600 uppercase tracking-widest">Conditionnement</label>

                <div class="flex gap-2">
                    <div class="flex flex-col gap-2 flex-1">
                        <InputNumber
                            id="conditioning_quantity"
                            type="conditioning_quantity"
                            ref="conditioning_quantity"
                            placeholder="Qt conditionnement"
                            :minFractionDigits="3"
                            v-model="form.conditioning_quantity"
                            :invalid="form.invalid('conditioning_quantity')"
                            @change="form.validate('conditioning_quantity')"
                        />
                        <small v-if="form.invalid('conditioning_quantity')" class="text-red-500"> {{ form.errors.conditioning_quantity }} </small>
                    </div>

                    <div class="flex flex-col gap-2 flex-1">
                        <InputNumber
                            id="inventory_conditioning_quantity"
                            type="inventory_conditioning_quantity"
                            ref="inventory_conditioning_quantity"
                            placeholder="Qt conditionnement inventaire"
                            :minFractionDigits="3"
                            v-model="form.inventory_conditioning_quantity"
                            :invalid="form.invalid('inventory_conditioning_quantity')"
                            @change="form.validate('inventory_conditioning_quantity')"
                        />
                        <small v-if="form.invalid('inventory_conditioning_quantity')" class="text-red-500"> {{ form.errors.inventory_conditioning_quantity }} </small>
                    </div>

                    <div class="flex flex-col gap-2 flex-1">
                        <Select
                            v-model="form.conditioning_id"
                            :options="conditioningsOptions"
                            optionLabel="name"
                            placeholder="Choisissez un conditionnement"
                            :invalid="form.invalid('conditioning_id')"
                        />
                        <small v-if="form.invalid('conditioning_id')" class="text-red-500"> {{ form.errors.conditioning_id }} </small>
                    </div>

                    <div class="flex flex-col gap-2 flex-1">
                        <Select
                            v-model="form.storage_unit_id"
                            :options="storageUnitsOptions"
                            optionLabel="name"
                            placeholder="Choisissez un conditionnement"
                            :invalid="form.invalid('storage_unit_id')"
                        />
                        <small v-if="form.invalid('storage_unit_id')" class="text-red-500"> {{ form.errors.storage_unit_id }} </small>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-2">
                <label for="price" class="text-dark-slate-600 uppercase tracking-widest">Prix (HTVA)</label>
                <InputNumber
                    id="price"
                    type="price"
                    ref="price"
                    currency="EUR"
                    locale="fr-BE"
                    :minFractionDigits="2"
                    :maxFractionDigits="5"
                    suffix=" €"
                    v-model="form.price"
                    :invalid="form.invalid('price')"
                    @change="form.validate('price')"
                />
                <small v-if="form.invalid('price')" class="text-red-500"> {{ form.errors.price }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="usage" class="text-dark-slate-600 uppercase tracking-widest">Utilisation(s)</label>
                <InputText
                    id="usage"
                    type="usage"
                    ref="usage"
                    v-model="form.usage[locale]"
                    :invalid="form.invalid('usage')"
                    @change="form.validate('usage')"
                />
                <small v-if="form.invalid('usage')" class="text-red-500"> {{ form.errors.usage }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="tags" class="text-dark-slate-600 uppercase tracking-widest">Tag(s)</label>
                <MultiSelect
                    v-model="form.tags"
                    :options="tagsOptions"
                    optionLabel="name"
                    placeholder="Choisissez un ou des tag(s)"
                    :invalid="form.invalid('tags')"
                />
                <small v-if="form.invalid('tags')" class="text-red-500"> {{ form.errors.tags }} </small>
            </div>

            <div class="flex gap-2">
                <div class="flex align-items-center">
                    <Checkbox
                        v-model="form.status"
                        inputId="status"
                        name="status"
                        :binary="true"
                        :class="form.invalid('status') ? 'p-invalid' : ''"
                    />
                    <label for="status" class="ml-2"> Actif </label>
                </div>
                <div class="flex align-items-center">
                    <Checkbox
                        v-model="form.is_essential"
                        inputId="is_essential"
                        name="is_essential"
                        :binary="true"
                        :class="form.invalid('is_essential') ? 'p-invalid' : ''"
                    />
                    <label for="is_essential" class="ml-2"> Essentiel </label>
                </div>
                <div class="flex align-items-center">
                    <Checkbox
                        v-model="form.inventory_enabled"
                        inputId="inventory_enabled"
                        name="inventory_enabled"
                        :binary="true"
                        :class="form.invalid('inventory_enabled') ? 'p-invalid' : ''"
                    />
                    <label for="inventory_enabled" class="ml-2"> Inventaire </label>
                </div>
            </div>

            <div class="flex flex-col gap-2">
                <label for="tags" class="text-dark-slate-600 uppercase tracking-widest">Visible pour</label>
                <MultiSelect
                    v-model="form.visible_for"
                    :options="categoriesOptions"
                    display="chip"
                    optionLabel="name"
                    empty-message="Aucune categories"
                    placeholder="Sélectionnez un ou plusieurs catégories"
                    :invalid="form.invalid('visible_for')"
                    @select="form.validate('visible_for')"
                />
                <small v-if="form.invalid('visible_for')" class="text-red-500"> {{ form.errors.visible_for }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="excluded_restaurants" class="text-dark-slate-600 uppercase tracking-widest">Masquer pour</label>
                <MultiSelect
                    v-model="form.excluded_restaurants"
                    :options="restaurantsOptions"
                    display="chip"
                    optionLabel="name"
                    empty-message="Aucun restaurants"
                    placeholder="Sélectionnez un ou plusieurs restaurant(s)"
                    label
                    :invalid="form.invalid('excluded_restaurants')"
                />
                <small v-if="form.invalid('excluded_restaurants')" class="text-red-500"> {{ form.errors.excluded_restaurants }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="accounting_type" class="text-dark-slate-600 uppercase tracking-widest">Type compta</label>
                <Select
                    v-model="form.accounting_type"
                    :options="accountingTypesOptions"
                    display="chip"
                    optionLabel="name"
                    placeholder="Sélectionnez le type"
                    :invalid="form.invalid('accounting_type')"
                    @select="form.validate('accounting_type')"
                />
                <small v-if="form.invalid('accounting_type')" class="text-red-500"> {{ form.errors.accounting_type }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="note" class="text-dark-slate-600 uppercase tracking-widest">Note produit</label>
                <InputText
                    id="note"
                    type="note"
                    ref="note"
                    v-model="form.note[locale]"
                    :class="form.invalid('note') ? 'p-invalid' : ''"
                    @change="form.validate('note')"
                />
                <small v-if="form.invalid('note')" class="p-error" id="text-error">
                    {{ form.errors.note }}
                </small>
            </div>

            <div>
                <Button @click="handleSubmit">
                    {{ product?.id ? 'Modifier' : 'Ajouter' }}
                </Button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import {computed, onBeforeMount, ref, watch} from "vue";

    import Breadcrumb from "primevue/breadcrumb";
    import InputText from "primevue/inputtext";
    import InputNumber from "primevue/inputnumber";
    import MultiSelect from "primevue/multiselect";
    import Button from "primevue/button";
    import ProgressSpinner from "primevue/progressspinner";
    import Checkbox from "primevue/checkbox";
    import Select from "primevue/select";

    import {useRoute, useRouter} from "vue-router";
    import {useForm} from "laravel-precognition-vue";

    import {storeToRefs} from "pinia";
    import {
        useRestaurantsStore,
        useConfigsStore,
        useSuppliersStore,
        useUsersStore,
        useProductsStore,
        useConditioningsStore, useTagsStore, useStorageUnitsStore
    } from "../../../store";
    import {UserType} from "../../../types/user";
    import {RestaurantType} from "../../../types/restaurant";
    import {SupplierType} from "../../../types/supplier";
    import {TagType} from "../../../types/tag";
    import {ConditioningType} from "../../../types/conditioning";
    import RESTAURANT_TYPES from "../../../enums/restaurant-types";
    import PRODUCT_ACCOUNTING_TYPES from "../../../enums/product-accounting-types";
    import PRODUCT_STATUS from "../../../enums/product-status"
    import {StorageUnitType} from "@/backoffice/types/storage-unit";

    const router = useRouter();
    const route = useRoute()

    const breadCrumbsFirstItem = computed( () => {
        return { label: 'Produits' , route: {name: 'products'} }
    })

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        if (product?.value?.id) {
            items.push({label: product?.value?.name[locale.value] || product?.value?.name || 'N/A'})
        }

        items.push({label: product?.value?.id ? 'Modifier' : 'Ajouter'})

        return items;
    });

    const configsStore = useConfigsStore();
    const configsRefs = storeToRefs(configsStore);
    const locale = configsRefs.locale;

    const productsStore = useProductsStore();
    const productsRefs = storeToRefs(productsStore);
    const product = productsRefs.single;

    const restaurantsStore = useRestaurantsStore();
    const restaurantsRefs = storeToRefs(restaurantsStore);
    const restaurantsOptions = ref<{ name: string, id: number }[]>([]);

    const usersStore = useUsersStore();
    const usersRefs = storeToRefs(usersStore);
    const usersOptions = ref<{ name: string, id: number }[]>([]);

    const tagsStore = useTagsStore();
    const tagsRefs = storeToRefs(tagsStore);
    const tagsOptions = ref<{ name: string, id: number }[]>([]);

    const suppliersStore = useSuppliersStore();
    const suppliersRefs = storeToRefs(suppliersStore);
    const supplier = suppliersRefs.single;
    const suppliersOptions = ref<{ name: string, id: number }[]>([]);

    const conditioningsStore = useConditioningsStore();
    const conditioningsRefs = storeToRefs(conditioningsStore);
    const conditioningsOptions = ref<{ name: string, id: number }[]>([]);

    const storageUnitsStore = useStorageUnitsStore();
    const storageUnitsRefs = storeToRefs(storageUnitsStore);
    const storageUnitsOptions = ref<{ name: string, id: number }[]>([]);

    const isPending = ref(false);

    const categoriesOptions = ref<{ name: string, id: number }[]>([]);

    categoriesOptions.value = Object.entries(RESTAURANT_TYPES.labels).map( type => {
        return {id: type[0], name: type[1]}
    });

    const accountingTypesOptions = ref<{ name: string, id: number }[]>([]);

    accountingTypesOptions.value = Object.entries(PRODUCT_ACCOUNTING_TYPES.labels).map( type => {
        return {id: type[0], name: type[1]}
    });

    onBeforeMount(async () => {
        isPending.value = true;

        if (route.params.id) {
            await productsStore.getItem({id: String(route.params.id), includes: ['excludedRestaurants', 'tags', 'conditioning', 'storageUnit']})
        } else {
            product.value = {
                name: {fr: null},
                supplier_reference: null,
                supplier: null,
                conditioning_quantity: null,
                inventory_conditioning_quantity: null,
                conditioning: null,
                price: null,
                accounting_type: null,
                usage: {fr: null},
                status: null,
                is_essential: false,
                inventory_enabled: false,
                visible_for: [],
                excluded_restaurants: [],
                note: {fr: null},
                tags: []
            }
        }

        await usersStore.getItems({list: true});
        usersOptions.value = usersRefs.list.value.map((user: UserType) => {
            return {name: user?.name, id: user.id}
        });

        await restaurantsStore.getItems({list: true});
        restaurantsOptions.value = restaurantsRefs.list.value.map((restaurant: RestaurantType) => {
            return {name: restaurant?.name, id: restaurant.id}
        });

        await tagsStore.getItems({list: true});
        tagsOptions.value = tagsRefs.list.value.map((tag: TagType) => {
            return {name: tag?.label, id: tag.id}
        });

        await suppliersStore.getItems({list: true});
        suppliersOptions.value = suppliersRefs.list.value.map((supplier: SupplierType) => {
            return {name: supplier?.name, id: supplier.id}
        });

        await conditioningsStore.getItems({list: true});
        conditioningsOptions.value = conditioningsRefs.list.value.map((conditioning: ConditioningType) => {
            return {name: conditioning?.label, id: conditioning.id}
        });

        await storageUnitsStore.getItems({list: true});
        storageUnitsOptions.value = storageUnitsRefs.list.value.map((storageUnit: StorageUnitType) => {
            return {name: storageUnit?.unit, id: storageUnit.id}
        });

        form.setData({
            ...product?.value,
            note: product?.value?.translatable?.note?.fr ? product?.value?.translatable?.note : {fr: ''},
            name: product?.value?.translatable?.name?.fr ? product?.value?.translatable?.name : {fr: ''},
            usage: product?.value?.translatable?.usage?.fr ? product?.value?.translatable?.usage : {fr: ''},
            conditioning_quantity: Number(product?.value?.conditioning_quantity),
            inventory_conditioning_quantity: product?.value?.inventory_conditioning_quantity ? Number(product?.value?.inventory_conditioning_quantity) : null,
            visible_for: product?.value?.visible_for ? categoriesOptions.value.filter((category:any) => Object.values(product.value.visible_for).includes(String(category.id))) : [],
            accounting_type: accountingTypesOptions.value.find((type:any) => product?.value?.accounting_type === type.id) || null,
            excluded_restaurants: product?.value?.excludedRestaurants ? restaurantsOptions.value.filter((restaurant:any) => product.value.excludedRestaurants.map( (restaurant:any) => Number(restaurant.id)).includes(Number(restaurant.id))) : [],
            tags: product?.value?.tags ? tagsOptions.value.filter((tag:any) => product.value.tags.map( (tag:any) => Number(tag.id)).includes(Number(tag.id))) : [],
            supplier_id: suppliersOptions.value.find((supplier:any) => Number(product?.value?.supplier_id) === Number(supplier.id)) || null,
            conditioning_id: conditioningsOptions.value.find((conditioning:any) => product?.value?.conditioning_id === conditioning.id) || null,
            storage_unit_id: storageUnitsOptions.value.find((storageUnit:any) => product?.value?.storage_unit_id === storageUnit.id) || null,
            status: product?.value?.status === 1
        })

        isPending.value = false;
    })

    interface FormObject {
        name: { fr: string };
        supplier_reference: string | null;
        supplier_id: number | null;
        conditioning_quantity: number | null;
        inventory_conditioning_quantity: number | null;
        conditioning_id: number | null;
        storage_unit_id: number | null;
        price: number | null;
        accounting_type: string | null;
        usage: { fr: string | null };
        status: string | null;
        is_essential: boolean;
        inventory_enabled: boolean;
        visible_for: UserType[];
        excluded_restaurants: RestaurantType[];
        tags: TagType[];
        note: { fr: string | null };
    }

    const form = useForm<FormObject>(
        route.params.id ? 'patch' : 'post',
        route.params.id
            ? '/api/products/' + route.params.id
            : '/api/products',
        {
            name: {fr: ''},
            supplier_reference: null,
            supplier_id: null,
            conditioning_quantity: null,
            inventory_conditioning_quantity: null,
            conditioning_id: null,
            storage_unit_id: null,
            price: null,
            accounting_type: null,
            usage: {fr: ''},
            status: null,
            is_essential: false,
            inventory_enabled: false,
            visible_for: [],
            excluded_restaurants: [],
            tags: [],
            note: {fr: null}
        }
    );

    watch(() => form.supplier_id, async () => {
        await storageUnitsStore.getItems({search: {supplier_id: form.supplier_id}});
        storageUnitsOptions.value = storageUnitsRefs.list.value.map((storageUnit: StorageUnitType) => {
            return {name: storageUnit?.unit, id: storageUnit.id}
        });
    })

    const handleSubmit = () => {
        productsRefs.isSavePending.value = true;

        let untouchedData = form.data();

        form
            .setData({
                excluded_restaurants: untouchedData?.excluded_restaurants?.map( (restaurant:RestaurantType) => restaurant.id ) || [],
                visible_for: untouchedData?.visible_for?.map( (user:UserType) => user.id ) || [],
                accounting_type: untouchedData?.accounting_type?.id,
                tags: untouchedData?.tags?.map( (tag:TagType) => tag.id ) || [],
                supplier_id: untouchedData?.supplier_id?.id,
                conditioning_id: untouchedData?.conditioning_id?.id,
                storage_unit_id: untouchedData?.storage_unit_id?.id,
                status: untouchedData?.status ? Number(PRODUCT_STATUS.values.ACTIVE.description) : Number(PRODUCT_STATUS.values.INACTIVE.description),
                is_essential: untouchedData?.is_essential ? 1 : 0,
                inventory_enabled: untouchedData?.inventory_enabled ? 1 : 0,
            })
            .submit()
            .then(() => {
                if (route.params.id) {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le produit a bien été modifié'})
                } else {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le produit a bien été créé'})
                }

                if(supplier?.value?.id) {
                    return router.push({name: 'supplier-show', params: {id: supplier.value.id}})
                }else {
                    return router.push({name: 'products'});
                }
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                } else if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                } else {
                   configsStore.addToast({severity: 'error', summary: 'Attention', detail: error.response.data.message})
                }

                form.setData({...untouchedData});
            })
            .finally(() => {
                productsRefs.isSavePending.value = false;
            })
    }
</script>
