<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div v-if="isFetchSinglePending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Planning fournisseur
                </h1>

                <Button @click="handleSavePlanning" v-if="authStore.isAdmin()">+ Ajouter un planning</Button>
            </div>
        </div>

        <DataTable
            :value="supplier?.plannings?.data"
            :loading="isFetchSinglePending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="supplier?.plannings?.data.length"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <Column header="Jour(s)" field="weekdays" #body="slotProps">
                {{ slotProps.data.weekdays.data.map( (day) => day.label ).join(', ') }}
            </Column>

            <Column header="Heure de clôture" field="hour" #body="slotProps">
                {{ slotProps.data.hour }} h
            </Column>

            <Column header="Délai(s)" field="delivery_days" #body="slotProps">
                {{ slotProps.data.delivery_days }} jour(s)
            </Column>

            <Column header="Restaurant(s)" field="restaurants" #body="slotProps">
                {{ slotProps.data.restaurants.data.map( (restaurant:RestaurantType) => restaurant.name).join(', ') }}
            </Column>

            <Column header="Note" field="note" />

            <Column key="actions" header="Action(s)">
                <template #body="slotProps">
                    <div class="flex items-center gap-2" v-if="authStore.isAdmin()">
                        <Button
                            link
                            label="Modifier"
                            @click.prevent="handleSavePlanning(slotProps.data)"
                            v-tooltip="'Éditer'">
                            <i class="pi pi-pencil" />
                        </Button>
                        <Button
                            link
                            label="Supprimer"
                            @click.prevent="handleDeletePlanning(slotProps.data)"
                            v-tooltip="'Supprimer'">
                            <i class="pi pi-trash" />
                        </Button>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount} from "vue";
    import {useAuthStore, useSuppliersStore} from "../../../store";
    import {storeToRefs} from "pinia";

    import {useRoute} from "vue-router";
    import Button from "primevue/button";
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";
    import ProgressSpinner from "primevue/progressspinner";
    import SavePlanning from "../../../components/dialogs/SavePlanning.vue";
    import {useDialog} from "primevue/usedialog";
    import {RestaurantType} from "../../../types/restaurant";
    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
    import {usePlanningsStore} from "@/backoffice/store/plannings";
    import {useConfirm} from "primevue/useconfirm";

    const route = useRoute();
    const dialog = useDialog();
    const confirm = useConfirm();

    const breadCrumbsFirstItem = computed( () => {
        return { label: 'Fournisseurs' , route: {name: 'suppliers'} }
    })

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        if (supplier?.value?.id) {
            items.push({label: supplier?.value?.name || 'N/A'})
        }

        items.push({label: 'Planning'})

        return items;
    });

    const suppliersStore = useSuppliersStore();
    const suppliersRefs = storeToRefs(suppliersStore);
    const isFetchSinglePending = suppliersRefs.isFetchSinglePending;
    const pagination = suppliersRefs.pagination
    const supplier = suppliersRefs.single

    const planningsStore = usePlanningsStore();

    const authStore = useAuthStore();

    onBeforeMount( () => {
        suppliersStore.getItem({id: route.params.id, includes: ['plannings', 'plannings.restaurants']})
    })

    const handleSavePlanning = (planning = null) => {
        dialog.open(SavePlanning, {
            props: {
                header: planning ? 'Modifier plage horaire' : 'Nouvelle plage horaire',
                modal: true
            },
            data: {
                supplier: supplier,
                planning: planning
            },
            onClose: () => {
                suppliersStore.getItem({id: route.params.id, includes: ['plannings', 'plannings.restaurants']})
            }
        })
    }


    const handleDeletePlanning = (planning:any) => {
        confirm.require({
            message: 'Êtes vous sur de vouloir supprimer ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Supprimer',
            rejectLabel: 'Annuler',
            acceptClass: 'p-button-danger',
            accept: () => { planningsStore.deleteItem(planning.id).then( () => suppliersStore.getItem({id: route.params.id, includes: ['plannings', 'plannings.restaurants']}) ) }
        })
    }
</script>
