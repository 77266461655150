<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div v-if="isFetchSinglePending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10 py-10">
        <div class="flex mb-8 justify-between items-center">
            <div class="flex items-center gap-2">
                <h1 class="mr-6 mb-2 text-5xl uppercase font-title">
                    Détail la commande #{{ order?.reference }}
                </h1>
                <template v-if="order?.status === 1">
                    <Tag :value="status[1]" severity="secondary" class="bg-surface-400"/>
                </template>
                <template v-if="order?.status === 2">
                    <Tag :value="status[2]" severity="info"/>
                </template>
                <template v-if="order?.status === 3">
                    <Tag :value="status[3]" severity="info" />
                </template>
                <template v-if="order?.status === 4">
                    <Tag :value="status[4]" severity="success" />
                </template>
                <template v-if="order?.status === 5">
                    <Tag :value="status[5]" style="background-color: #256556" />
                </template>
                <template v-if="order?.status === 6">
                    <Tag :value="status[6]" severity="danger" />
                </template>
                <template v-if="order?.status === 3">
                    <Button
                        label="Réceptionner"
                        @click.prevent="handleReceiveOrder()"
                        size="small"
                        severity="warning"
                        v-tooltip="'Réceptionner'">
                        <i class="pi pi-box"></i>
                    </Button>
                </template>
                <template v-if="order.status === 4">
                    <Button
                        label="Réconcilier"
                        @click.prevent="handleReconcileOrder(order)"
                        size="small"
                        severity="secondary"
                        v-tooltip="'Réconcilier'">
                        <i class="pi pi-clipboard"></i>
                    </Button>
                </template>
                <template v-if="order.status === 1 || order.status === 2">
                    <Button
                        link
                        label="Modifier"
                        @click.prevent="handleCreateOrEditOrder(order)"
                        v-tooltip="'Éditer'">
                        <i class="pi pi-pencil"></i>
                    </Button>
                </template>
                <Button
                    v-if="order.status === 6"
                    link
                    label="Modifier"
                    @click.prevent="handleCreditNoteNumber"
                    v-tooltip="'Ajouter note de crédit'">
                    <i class="pi pi-check"></i>
                </Button>
            </div>
            <div>
                <Button
                    label="Export en XLS"
                    @click.prevent="handleExportXLS"
                    size="small"
                    severity="success"
                    v-tooltip="'Export en XLS'">
                </Button>
            </div>
        </div>

        <div class="mb-4">
            <p v-if="order.planned_at" ><strong>Date de commande</strong>: {{ new Intl.DateTimeFormat('fr-BE').format(new Date(order.planned_at)) }}</p>
            <p v-if="order.received_at"><strong>Date de réception</strong>: {{ new Intl.DateTimeFormat('fr-BE').format(new Date(order.received_at)) }}</p>
            <p v-if="order.invoiced_at"><strong>Date de facture</strong>: {{ new Intl.DateTimeFormat('fr-BE').format(new Date(order.invoiced_at)) }}</p>
        </div>

        <div class="flex justify-end mb-6">
            <Search @search="handleGlobalSearch" />
        </div>

        <DataTable
            :value="orderItems"
            :loading="isFetchSinglePending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :globalFilterFields="['status']"
            :totalRecords="order?.items?.data.length"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy
            paginator
            showGridlines
            stripedRows
            removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                root: {class: 'mb-12'}
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <Column header="Nom" field="label" :sortable="true" #body="slotProps">
                {{ slotProps.data.label }}
                <template v-if="slotProps.data.product.note">
                    <i class="text-orange-500 pi pi-exclamation-triangle"
                       v-tooltip="slotProps.data.product.note"
                    ></i>
                </template>
            </Column>

            <Column header="Prix" field="price" :sortable="true" #body="slotProps">
                {{ slotProps.data.product.price.toFixed(2) }} €
            </Column>

            <Column header="Conditionnement" field="conditioning" #body="slotProps">
                {{ slotProps.data.extra_attributes.conditioning_quantity }}
                {{ slotProps.data.extra_attributes.conditioning_label }}
            </Column>

            <Column header="Q. Commandée" field="quantity" :sortable="true" />

            <Column header="Q. Reçue" field="received_quantity" :sortable="true" #body="slotProps">
                {{ slotProps.data.received_quantity }}
                <template v-if="complaints[0]?.items?.data?.find( (item:any) => Number(item.product_id) === slotProps.data.product_id)">
                    <i class="text-red-500 pi pi-exclamation-triangle"
                       v-tooltip="'Produit signalé pour : ' + complaints[0]?.items?.data?.find( (item:any) => Number(item.product_id) === slotProps.data.product_id).reason.translatable.label[locale]"
                    ></i>
                </template>
            </Column>

            <Column header="Q. Facture" field="invoiced_quantity" :sortable="true"></Column>
        </DataTable>

        <div class="mb-6 flex justify-between">
            <div>
                <p><strong>TOTAL REÇU:</strong>
                    {{ orderItems.reduce((sum:number, item:any) => sum + Number(item.received_quantity), 0) }}
                    ({{ orderItems.reduce((sum:number, item:any) => sum + (Number(item.price) * Number(item.received_quantity)), 0).toFixed(2) }} €)
                </p>
                <p><strong>TOTAL FACTURE:</strong>
                    {{ orderItems.reduce((sum:number, item:any) => sum + Number(item.invoiced_quantity), 0) }}
                    ({{ orderItems.reduce((sum:number, item:any) => sum + (Number(item.price) * Number(item.invoiced_quantity)), 0).toFixed(2) }} €)</p>
            </div>
        </div>

        <div class="flex gap-2 flex-col mb-6" :key="'note-'+key">
            <label for="note" class="text-surface-400 tracking-widest">Commentaires</label>
            <Editor id="note" name="note" class="w-full" v-model="form.note"/>
            <small v-if="form.invalid('note')" class="text-red-500"> {{ form.errors.note }} </small>
        </div>

        <div class="flex-1 mb-6" :key="'uploads-'+key">
            <FilePckr
                id="uploads"
                multiple
                v-model="form.uploads"
                @start-load="handleStartLoadFiles"
                @end-load="handleEndLoadFiles"
            />
        </div>

        <div class="flex justify-between mb-6 " :key="'notify_by_email-'+key">
            <div class="flex gap-2 items-center justify-between">
                <label class="font-bold" for="send_email">Envoyer par email</label>
                <ToggleSwitch id="notify_by_email" name="notify_by_email" v-model="form.notify_by_email" />
            </div>
            <Button @click.prevent="handleSubmit" :disabled="isFilepondPending">Envoyer</Button>
        </div>

        <div class="flex gap-4 flex-col">
            <Logs :logs="logs" :is-loading="isFetchLogsPending"/>
        </div>
    </div>
</template>
<script setup lang="ts">
import {computed, onBeforeMount, ref} from "vue";
import {useComplaintsStore, useConfigsStore, useOrdersStore } from "../../../store";
import {storeToRefs} from "pinia";

import Editor from "primevue/editor";
import Button from "primevue/button"
import ProgressSpinner from "primevue/progressspinner";
import ToggleSwitch from "primevue/toggleswitch";
import Column from "primevue/column";
import DataTable from "primevue/datatable";

import {useRoute, useRouter} from "vue-router";
import {useForm} from "laravel-precognition-vue";
import Tag from "primevue/tag";

import ORDER_STATUS from "../../../enums/order-status"
import FilePckr from "../../../components/file-pckr.vue";
import {OrderType} from "../../../types/order";
import Axios from "axios";

import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue"
import Search from "../../../components/partials/backoffice/search.vue"
import Logs from "../../../components/partials/backoffice/logs.vue"
import {useDialog} from "primevue/usedialog";
import AddCreditNoteNumber from "../../../components/dialogs/AddCreditNoteNumber.vue";
import useDatatableActions from "../../../composables/datatable-actions";

const breadCrumbsFirstItem = computed( () => { return { label: 'Commandes' , route: {name: 'orders' } } } );

const breadCrumbsItems = computed(() => {
    let items: { label: string, to?: { name: string, params?: any } } [] = [];

    items.push({label: order?.value?.restaurant.name})

    items.push({label: order?.value?.supplier.name})

    items.push({label: 'Détail'})

    return items;
});

const status = ORDER_STATUS.labels;

const router = useRouter();
const route = useRoute()

const ordersStore = useOrdersStore();
const {isFetchSinglePending, single: order, logs, isFetchLogsPending, isSavePending, pagination, items: orderItems, isFetchItemsPending } = storeToRefs(ordersStore);

const complaintsStore = useComplaintsStore();
const {list: complaints} = storeToRefs(complaintsStore)

const configsStore = useConfigsStore();
const { locale, appUrl } = storeToRefs(configsStore)

const queryParams = ref({
    id: route.params.id,
    search: {
        search: null,
    },
    includes: ['product'],
    sort_by: null,
    desc: false,
})

const {handlePageChange, handleSortChange, handleGlobalSearch} = useDatatableActions(pagination, queryParams, ordersStore.getOrderItems)

onBeforeMount(async () => {
    await ordersStore.getItem({id: route.params.id})
    await ordersStore.getOrderItems(queryParams.value)
    await complaintsStore.getItems({search: {order_id: route.params.id}, includes: ['items', 'items.reason']});

    await ordersStore.getLogs({id: Number(route.params.id), includes: ['uploads']});

    form.setData({
        items : orderItems.value,
        status: order?.value?.status
    })
});

const isFilepondPending = ref(false);

const handleStartLoadFiles = ({files, id}: { files: [], id: number }) => {
    form.setData({[id]: files.map((file: { serverId: string }) => file.serverId)})
    isFilepondPending.value = true;
}

const handleEndLoadFiles = ({files, id}: { files: [], id: number }) => {
    form.setData({[id]: files.map((file: { serverId: string }) => file.serverId)})
    isFilepondPending.value = false;
}

const form = useForm(
    'post',
    '/api/orders/' + route.params.id + '/logs',
    {
        note: null,
        uploads: [],
        notify_by_email: false
    }
);

const handleReceiveOrder = () => {
    router.push({ name: 'order-receive', params: { order_id: order.value.id} });
}

const handleExportXLS = () => {
    Axios.post(`${appUrl.value}/api/orders/${order.value.id}/export`).then( (response) => {
        window.open(response.data.data.url);
    })
}

const key = ref(0)

const handleSubmit = () => {
    isSavePending.value = true;

    let untouchedData = form.data();

    form
        .submit()
        .then(async () => {
            configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'La commande a bien été modifiée'})

            await ordersStore.getLogs({id: Number(route.params.id)});

            form.setData({
                note: null,
                uploads: [],
                notify_by_email: false
            })

            key.value++
        })
        .catch(error => {
            if (error.response.status === 422) {
                configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
            }
            if (error.response.status === 500) {
                configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
            }

            form.setData({...untouchedData});
        })
        .finally(() => {
            isSavePending.value = false;
        })
}

const handleReconcileOrder = (order:OrderType) => {
    router.push({ name: 'order-reconcile', params: { id: order.id} });
}

const handleCreateOrEditOrder = (order:OrderType) => {
    ordersRefs.single.value = null
    router.push({name: 'order-save',  params: (order ? {id: order.id} : {}) });
}

const dialog = useDialog();

const handleCreditNoteNumber = () => {
    dialog.open(AddCreditNoteNumber, {
        props: {
            header: 'Note de crédit',
            modal: true
        },
        data: {
            order: order
        },
        onClose: () => {
            router.push({name: 'orders'});
        }
    })
}
</script>
