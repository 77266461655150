export default {
    values: Object.freeze ({
        A: Symbol('A'),
        B: Symbol('B'),
        NA: Symbol('NA'),
    }),
    labels: {
        A: 'A',
        B: 'B',
        NA: 'NA'
    }
}

